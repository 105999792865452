import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import qs from 'qs'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { get } from 'lodash'

import SEO from '../../components/SEO'
import { Form, Loader } from '../../_shared/components'
import { trackStepForGTM } from '../../lib/ga'

export default function Page() {
  useEffect(() => {
    trackStepForGTM('download')
  }, [])

  const location = useLocation()
  const { invoiceId, token } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const {
    loading,
    data: { downloadStatus = null } = {},
    error,
    stopPolling,
  } = useQuery(
    gql`
      query CheckDownloadStatus($invoiceId: Int, $token: String) {
        downloadStatus(invoiceId: $invoiceId, token: $token) {
          ready
          generated
          pending
        }
      }
    `,
    {
      variables: { invoiceId: Number(invoiceId), token },
      pollInterval: 5000,
      skip: !invoiceId || !token
    },
  )

  useEffect(() => {
    if (!invoiceId || !token) {
      stopPolling()
    }

    if (downloadStatus && downloadStatus.ready) {
      stopPolling()
    }
  }, [invoiceId, token, downloadStatus, stopPolling])

  const ready = get(downloadStatus, 'ready', false)
  const pending = get(downloadStatus, 'pending', 0)

  const makeContent = () => {
    const items = []

    if (error && error.message.match(/Invalid invoiceId or token/)) {
      items.push({
        item: 'text',
        value:
          'Ugyldig nedlastingslenke. Om du kopierte lenken for hånd, sjekk at hele kom med.',
      })
    } else if (!error && downloadStatus && ready) {
      items.push({
        item: 'text',
        value:
          'Alt er klart. Klikk på lenken nedenfor for å laste ned en zip-fil som inneholder alle gavekortene i printbart PDF-format.',
      })
      items.push({
        item: 'button',
        label: 'Last ned gavekort',
        to: `https://hooks.foodsteps.no/download/pdf.zip?invoiceId=${invoiceId}&token=${token}`,
        id: 'download',
        primary: true,
        icon: 'download',
        full: true,
      })
    } else if (!error && downloadStatus && pending > 0) {
      items.push(
        {
          item: 'text',
          value: `Vi gjør klar PDFene. ${pending} gavekort gjenstår.`,
        },
        {
          item: 'custom',
          children: [<Loader key="loader" />],
        },
      )
    }

    return items
  }

  const LOADING_STEP = {
    title: 'Nedlasting av gavekort',
    items: [],
  }

  const COMPLETED_STEP = {
    title: 'Nedlasting av gavekort',
    close: true,
    items: makeContent(),
  }

  return (
    <>
      <SEO title="Gavekort kjøp gjennomført" />

      <Form
        step="completed"
        setStep={() => {}}
        handleBack={() => navigate('/gavekort')}
        navigation
        steps={{
          completed: loading ? LOADING_STEP : COMPLETED_STEP,
        }}
      />
    </>
  )
}
